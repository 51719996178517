import classNames from 'classnames';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MenuAndHeader from 'components/menu-and-header/MenuAndHeader';
import adDetails from 'features/adDetails';
import SalesAuthorization from 'features/agricultural/ SalesAuthorization';
import AgriculturalMenu from 'features/agricultural/AgriculturalMenu';
import AgriculturalQuotation from 'features/agricultural/AgriculturalQuotation';
import AuthorizationForConsult from 'features/agricultural/AuthorizationForConsult';
import ConsultSalesAuthorization from 'features/agricultural/ConsultSalesAuthorization';
import ProductWithdrawAuthorization from 'features/agricultural/ProductWithdrawAuthorization';
import CostOptions from 'features/agricultural/CostOptions';
import GrainReceiptQueue from 'features/agricultural/GrainReceiptQueue';
import Inputs from 'features/agricultural/Inputs';
import LoadByLoad from 'features/agricultural/LoadByLoad';
import MyStock from 'features/agricultural/MyStock';
import Package from 'features/agricultural/Package';
import ProductionDischarge from 'features/agricultural/ProductionDischarge';
import Profitability from 'features/agricultural/Profitability';
import SalesStatement from 'features/agricultural/SalesStatement';
import ScheduleBalance from 'features/agricultural/ScheduleBalance';
import ThirdPartyAuthorization from 'features/agricultural/ThirdPartyAuthorization';
import Announcements from 'features/Announcements';
import AuthorizationForm from 'features/authorizations/AuthorizationForm';
import AuthorizationFuelStation from 'features/authorizations/AuthorizationFuelStation';
import CalendarComponent from 'features/CalendarComponent';
import Channel from 'features/channel/Channel';
import Videos from 'features/channel/Videos';
import ClassifiedAds from 'features/ClassifiedAds';
import CooperativeRoom from 'features/CooperativeRoom';
import Dashboard from 'features/dashboard/Dashboard';
import Environmental from 'features/Environmental';
import CapitalAccount from 'features/financial/CapitalAccount';
import ComplementarySeedBonus from 'features/financial/ComplementarySeedBonus';
import Financial from 'features/financial/Financial';
import FinancialAbstract from 'features/financial/FinancialAbstract/FinancialAbstract';
import FinancialPosition from 'features/financial/FinancialPosition';
import FinancialPositionAnalytic from 'features/financial/FinancialPositionAnalytic';
import MonthlyTransaction from 'features/financial/MonthlyTransaction';
import MonthlyTransactionByProvider from 'features/financial/MonthlyTransactionByProvider';
import MonthlyTransactionInvoice from 'features/financial/MonthlyTransactionInvoice';
import Taxes from 'features/financial/Taxes';
import TransferBetweenAccounts from 'features/financial/TransferBetweenAccounts';
import TransferByRegister from 'features/financial/TransferByRegister';
import Forest from 'features/Forest';
import Home from 'features/home/home';
import BullCatalog from 'features/livestock/cattle/BullCatalog';
import BullDetails from 'features/livestock/cattle/BullDetails';
import Cattle from 'features/livestock/cattle/Cattle';
import CattleDocuments from 'features/livestock/cattle/CattleDocuments';
import FeedOrder from 'features/livestock/cattle/FeedOrder';
import MilkAnalysis from 'features/livestock/cattle/MilkAnalysis';
import MilkAdvancedPayment from 'features/financial/MilkAdvancedPayment';
import MilkCollections from 'features/livestock/cattle/MilkCollections';
import MilkIndicators from 'features/livestock/cattle/MilkIndicators';
import MilkMap from 'features/livestock/cattle/MilkMap';
import MySquad from 'features/livestock/cattle/MySquad';
import DrugProgrammingBalance from 'features/livestock/DrugProgrammingBalance';
import NewFeedOrder from 'features/livestock/NewFeedOrder';
import RawMaterialSchedule from 'features/livestock/RawMaterialSchedule';
import PigDeliveryReport from 'features/livestock/swine/PigDeliveryReport';
import SlaughterReport from 'features/livestock/swine/SlaughterReport';
import Swine from 'features/livestock/swine/Swine';
import SwineDocuments from 'features/livestock/swine/SwineDocuments';
import TrrRequestsSwine from 'features/livestock/TrrRequestsSwine';
import Login from 'features/login/login';
import Logout from 'features/logout/logout';
import NewPassword from 'features/newPassword/newPassword';
import PlayerVideo from 'features/PlayerVideo';
import EnvironmentalProperties from 'features/registration-form/EnvironmentalProperties';
import PersonalData from 'features/registration-form/PersonalData';
import Machines from 'features/registration-form/Machines';
import Benfeitorias from 'features/registration-form/Infrastructure';
import Properties from 'features/registration-form/Properties';
import RegistrationForm from 'features/registration-form/RegistrationForm';
import Squad from 'features/registration-form/Squad';
import search from 'features/search/search';
import Shortcuts from 'features/shortcuts/Shortcuts';
import Support from 'features/Support';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import authenticationService from 'services/authentication-service';
import PageNotFound from 'shared/error/page-not-found';
import AuthUtils from 'shared/util/auth-utils';
import EnvironmentalMenu from 'features/environmental/EnvironmentalMenu';
import EnvironmentalDocument from 'features/environmental/EnvironmentalDocument';

const PrivateRoutes = ({ component: Component, supportOnly = false, ...rest }) => {
  const history = useHistory();

  const getCDPerfil = useSelector<IRootState, number>(state => {
    return state.authentication.coreInfo?.cd_perfil;
  });

  const getCDPerson = useSelector<IRootState, any>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA;
  });

  const privatedPaths = [
    '/financeiro',
    '/agricola',
    '/pecuaria-bovinos',
    '/pecuaria-suinos',
    '/configuracoes',
    '/cotacoes-agricola',
    '/fale-com-a-isa',
    '/ambiental',
    '/autorizacao-compras',
  ];

  const checkAcess = async () => {
    for (let value of privatedPaths) {
      if (rest.path.includes(value)) {
        await authenticationService.checkPageAcess(getCDPerson.toString(), rest.path).catch(result => {
          if (result.status === 500) {
            history.push('/');
          }
        });
      }
    }
  };

  useEffect(() => {
    checkAcess();
  }, []);

  if (supportOnly && getCDPerfil === 3) {
    return (
      <Route
        {...rest}
        render={props => (AuthUtils.isAuthenticated() ? <Component {...props} /> : <Redirect push to={{ pathname: '/' }} />)}
      />
    );
  } else if (supportOnly) {
    return <Route {...rest} render={props => <Redirect push to={{ pathname: '/' }} />} />;
  } else {
    return (
      <Route
        {...rest}
        render={props => (AuthUtils.isAuthenticated() ? <Component {...props} /> : <Redirect push to={{ pathname: '/' }} />)}
      />
    );
  }
};

const AppRoutes = ({ match, isAuthenticated }) => {
  const appRoutesClassName = isAuthenticated ? 'has-header' : '';

  return (
    <div className={classNames([appRoutesClassName, 'App-routes-wrapper'])}>
      <div className={'App-routes-wrap'}>
        <BrowserRouter>
          <Switch>
            <Route exact path={match.url} component={Login} />
            <Route exact path={'/login'} component={Login} />
            <Route exact path={'/index'} component={Login} />
            <Route exact path={'/novo-password'} component={NewPassword} />
            <Route exact path={'/home'} component={Home} />
            <Route exact path={'/logout'} component={Logout} />
            <PrivateRoutes exact path={'/search'} component={search} />
            <MenuAndHeader>
              <Switch>
                <PrivateRoutes exact path={'/suporte'} component={Support} supportOnly />
                <PrivateRoutes exact path={'/inicial'} component={Dashboard} />
                <PrivateRoutes exact path={'/atalhos'} component={Shortcuts} />
                <PrivateRoutes exact path={'/comunicados'} component={Announcements} />
                <PrivateRoutes exact path={'/canal-frisia'} component={Channel} />
                <PrivateRoutes path={'/canal-frisia/videos'} component={Videos} />
                <PrivateRoutes path={'/canal-frisia/play/:id'} component={PlayerVideo} />
                <PrivateRoutes exact path={'/financeiro'} component={Financial} />
                <PrivateRoutes exact path={'/financeiro/movimento-mensal'} component={MonthlyTransaction} />
                <PrivateRoutes exact path={'/financeiro/movimento-mensal-notas-fiscais'} component={MonthlyTransactionInvoice} />
                <PrivateRoutes exact path={'/financeiro/movimento-mensal-por-fornecedor'} component={MonthlyTransactionByProvider} />
                <PrivateRoutes exact path={'/financeiro/imposto-de-renda'} component={Taxes} />
                <PrivateRoutes exact path={'/financeiro/posicao-financeira-disabled01-11-2023'} component={FinancialPosition} />
                <PrivateRoutes exact path={'/financeiro/posicao-financeira'} component={FinancialPositionAnalytic} />
                <PrivateRoutes exact path={'/financeiro/cota-capital'} component={CapitalAccount} />
                <PrivateRoutes exact path={'/financeiro/bonificacao-sementes'} component={ComplementarySeedBonus} />
                <PrivateRoutes exact path={'/financeiro/transferencia-entre-matriculas'} component={TransferByRegister} />
                <PrivateRoutes exact path={'/financeiro/transferencia-entre-contas'} component={TransferBetweenAccounts} />
                <PrivateRoutes exact path={'/financeiro/extratos'} component={FinancialAbstract} />
                <PrivateRoutes exact path={'/financeiro/saldo'} component={FinancialAbstract} />
                <Route exact path={'/agenda'} component={CalendarComponent} />
                <PrivateRoutes exact path={'/cotacoes-agricola'} component={AgriculturalQuotation} />
                <PrivateRoutes exact path={'/agricola'} component={AgriculturalMenu} />
                <PrivateRoutes path={'/agricola/embalagens'} component={Package} />
                <PrivateRoutes path={'/agricola/meu-estoque'} component={MyStock} />
                <PrivateRoutes path={'/agricola/carga-a-carga'} component={LoadByLoad} />
                <PrivateRoutes path={'/agricola/rentabilidade'} component={Profitability} />
                <PrivateRoutes path={'/agricola/descarga-de-producao'} component={ProductionDischarge} />
                <PrivateRoutes path={'/agricola/pedido-trr'} component={TrrRequestsSwine} />
                <PrivateRoutes path={'/agricola/fila-recebimento-grao'} component={GrainReceiptQueue} />
                <PrivateRoutes exact path={'/agricola/demonstrativo-de-vendas'} component={SalesStatement} />
                <PrivateRoutes path={'/autorizacao-compras'} component={ThirdPartyAuthorization} />
                <PrivateRoutes path={'/agricola/orcamento-insumos'} component={Inputs} />
                <PrivateRoutes exact path={'/agricola/saldo-de-programacao'} component={ScheduleBalance} />
                {/* <PrivateRoutes path={'/agricola/opcoes-de-custeio'} component={CostOptions} /> */}
                <PrivateRoutes path={'/agricola/autorizacao-de-vendas'} component={SalesAuthorization} />
                <PrivateRoutes path={'/agricola/consulta-autorizacao-de-vendas'} component={ConsultSalesAuthorization} />
                <PrivateRoutes path={'/agricola/autorizacao-de-retirada-de-produtos'} component={ProductWithdrawAuthorization} />
                <PrivateRoutes exact path={'/pecuaria-bovinos'} component={Cattle} />
                <PrivateRoutes path={'/pecuaria-bovinos/mapa-leite'} component={MilkMap} />
                <PrivateRoutes path={'/pecuaria-bovinos/coletas-leite'} component={MilkCollections} />
                <PrivateRoutes path={'/pecuaria-bovinos/analise-leite'} component={MilkAnalysis} />
                <PrivateRoutes path={'/pecuaria-bovinos/adiantamento-leite'} component={MilkAdvancedPayment} />
                <PrivateRoutes path={'/pecuaria-bovinos/indicadores-leite'} component={MilkIndicators} />
                <PrivateRoutes path={'/pecuaria-bovinos/saldo-farmacos'} component={DrugProgrammingBalance} />
                <PrivateRoutes path={'/pecuaria-bovinos/pedido-trr'} component={TrrRequestsSwine} />
                <PrivateRoutes exact path={'/pecuaria-bovinos/pedido-racao'} component={FeedOrder} />
                <PrivateRoutes path={'/pecuaria-bovinos/pedido-racao/novo-pedido'} component={NewFeedOrder} />
                <PrivateRoutes path={'/pecuaria-bovinos/documentos'} component={CattleDocuments} />
                <PrivateRoutes exact path={'/pecuaria-bovinos/meu-plantel'} component={MySquad} />
                <PrivateRoutes exact path={'/pecuaria-bovinos/catalogo-touros'} component={BullCatalog} />
                <PrivateRoutes path={'/pecuaria-bovinos/catalogo-touros/detalhes'} component={BullDetails} />
                <PrivateRoutes path={'/pecuaria-bovinos/programacao-materia-prima'} component={RawMaterialSchedule} />
                <PrivateRoutes exact path={'/pecuaria-suinos'} component={Swine} />
                <PrivateRoutes path={'/pecuaria-suinos/relatorio-entrega-de-suinos'} component={PigDeliveryReport} />
                <PrivateRoutes path={'/pecuaria-suinos/relatorio-abate'} component={SlaughterReport} />
                <PrivateRoutes exact path={'/pecuaria-suinos/pedido-racao'} component={FeedOrder} />
                <PrivateRoutes path={'/pecuaria-suinos/pedido-racao/novo-pedido'} component={NewFeedOrder} />
                <PrivateRoutes path={'/pecuaria-suinos/pedido-trr'} component={TrrRequestsSwine} />
                <PrivateRoutes path={'/pecuaria-suinos/documentos'} component={SwineDocuments} />
                <PrivateRoutes path={'/pecuaria-suinos/saldo-farmacos'} component={DrugProgrammingBalance} />
                <PrivateRoutes path={'/pecuaria-suinos/programacao-materia-prima'} component={RawMaterialSchedule} />
                <PrivateRoutes
                  path={'/alegra'}
                  component={() => {
                    window.location.href = 'https://alegra.minhagranja.com.br/login';
                    return (
                      <LoadingContainerScreen isLoading={true}>
                        <div />
                      </LoadingContainerScreen>
                    );
                  }}
                />
                <PrivateRoutes path={'/florestal'} component={Forest} />
                <PrivateRoutes exact path={'/ambiental'} component={EnvironmentalMenu} />
                <PrivateRoutes exact path={'/ambiental/licenca-ambiental'} component={Environmental} />
                <PrivateRoutes exact path={'/ambiental/documento-ambiental'} component={EnvironmentalDocument} />
                <PrivateRoutes path={'/autorization-form'} component={AuthorizationForm} />
                <PrivateRoutes path={'/autorizacao-consultores'} component={AuthorizationForConsult} />
                <PrivateRoutes path={'/autorizacao-convenio-posto'} component={AuthorizationFuelStation} />
                <PrivateRoutes exact path={'/ficha-cadastral'} component={RegistrationForm} />
                <PrivateRoutes exact path={'/ficha-cadastral/dados-pessoais'} component={PersonalData} />
                <PrivateRoutes exact path={'/ficha-cadastral/equipamentos'} component={Machines} />
                <PrivateRoutes exact path={'/ficha-cadastral/benfeitorias'} component={Benfeitorias} />
                <PrivateRoutes path={'/ficha-cadastral/propriedades'} component={Properties} />
                <PrivateRoutes path={'/ficha-cadastral/propriedades-ambiental'} component={EnvironmentalProperties} />
                <PrivateRoutes path={'/ficha-cadastral/plantel'} component={Squad} />
                <PrivateRoutes path={'/sala-do-cooperado'} component={CooperativeRoom} />
                <PrivateRoutes exact path={'/classificados'} component={ClassifiedAds} />
                <PrivateRoutes path={'/classificados/detalhes/:id'} component={adDetails} />
              </Switch>
            </MenuAndHeader>

            <Route path={'*'} component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
};

export default AppRoutes;
